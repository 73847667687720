import request from '@/system/engine/request';
import config from '@/system/engine/config';

export function postMethod(url,postData){
	const data = request({
		url: config.url+url,
		method: 'post',
		data: postData
	});
	
	return Promise.resolve(data);
}