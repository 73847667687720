<template>
	<div class="app-container">		
		<el-button type="primary" :loading="buttonloading" class="mt-3 mb-3 float-right" v-b-modal.addRow v-if="permissionList.includes(permission.add)" icon="el-icon-plus">{{$t('button.add')}}</el-button>
		<el-table :data="tableData" v-loading="loading" style="width: 100%;" @expand-change="expandChange" @row-click="expandRow" class="mt-3" ref="tableTest" border fit highlight-current-row>
            <el-table-column v-for="title in ajaxTitles" :prop="title.prop" :label="title.label" :key="title.prop" :min-width="title.width">
                <template slot="header">
                    <p class="search-label">{{title.label}}</p>
                    <b-form-input :placeholder="title.label" @keyup.enter.native="initial" v-model="searchData[title.prop]" @change="saveData()"></b-form-input>
                </template>
            </el-table-column>
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-button type="primary" :loading="buttonloading" @click="getInfoRow(scope.row.id)" v-if="permissionList.includes(permission.info)" icon="el-icon-info">{{$t('button.info')}}</el-button>
                    <!-- <el-button type="danger" :loading="buttonloading" @click="deleteRow(scope.row.id)" v-if="permissionList.includes(permission.delete)" icon="el-icon-delete">{{$t('button.delete')}}</el-button> -->
                </template>
            </el-table-column>
        </el-table>

		<pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="pagination"/>
		
		<el-tooltip placement="top" :content="$t('general.back_to_top')">
			<backtotop :visibility-height="300" :back-position="50" transition-name="fade" />
		</el-tooltip>

		<b-modal id="infoRow" :title="$t('admin_general.table_mail_info')" size="lg" hide-footer>
			<table class="table table-bordered" style="font-size:14px;">
				<tbody>
					<tr>
						<td width="15%">{{$t('admin_general.table_name')}}</td>
						<td width="35%">{{dataList.name}}</td>
						<td width="15%">{{$t('admin_general.table_email')}}</td>
						<td width="35%">{{dataList.email}}</td>
					</tr>
					<tr>
						<td width="15%">{{$t('admin_general.table_title')}}</td>
						<td width="35%"><strong>{{dataList.subject}}</strong></td>
						<td width="15%">{{$t('admin_general.table_created_at')}}</td>
						<td width="35%">{{dataList.created_at}}</td>
					</tr>
					<tr>
						<td width="15%" >{{$t('admin_general.table_content')}}</td>
						<td width="35%" height="200px" colspan="3">{{dataList.content}}</td>
					</tr>
				</tbody>
			</table>
			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('infoRow')">{{$t('button.cancel')}}</el-button>
			</el-footer>
        </b-modal>

	</div>
</template>

<script>
import {getLocalStorage} from '@/system/store/localstorage';
import {postMethod} from '@/system/model/post/post';
import {getDAES, getEAES} from '@/system/library/security';
import pagination from '@/components/pagination';
import backtotop from '@/components/backtotop';

let searchForm = {
	pagination: 1,
	limit: 10,
	id: '',
	created_at:'',
	name:'',
	subject:'',
	email:'',
}
			
export default{
	components: { pagination, backtotop },
	inject:['preloader'],
	data(){
		return {
			loading: true,
			buttonloading: false,
			tableData: [],
			total: 0,
			errors: [],
			submitForm: {
				id:'',
			},
			postData: {
				data: '',
				language: '',
			},
			searchData: Object.assign({}, searchForm),
			listQuery: {
				page: 1,
				limit: 10
			},
			ajaxTitles:[{
                prop: "id",
                label: this.$t('admin_general.table_id'),
                width:'50'
            },{
                prop: "created_at",
                label: this.$t('admin_general.table_created_at'),
                width:'100'
			},{
                prop: "name",
                label: this.$t('admin_general.table_name'),
                width:'100'
			},{
                prop: "email",
                label: this.$t('admin_general.table_email'),
                width:'100'
			},{
                prop: "subject",
                label: this.$t('admin_general.table_title'),
                width:'100'
			}],
			coinList: [],
			dataList:{
				name:'',
                email:10,
                subject:'',
			},
			permission:{
                ajaxTable:'FQ9EQU',
                info:'VR4RNC',
            },
            permissionList:[],
            languageList:[],
			pickerOptions: {
				shortcuts: [{
					text: this.$t('general.recent_week'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				},{
					text: this.$t('general.recent_one_month'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				},{
					text: this.$t('general.recent_three_month'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
            },
		}
	}, methods: {
		initial(){
			if(this.permissionList.includes(this.permission.ajaxTable)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var self = this;
				var result = postMethod('report/emailservice/ajaxTable',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.tableData = data.datatable.data;
						self.total = parseInt(data.datatable.total_number);
						self.listQuery.page = parseInt(data.datatable.current_pagination);
						self.listQuery.limit = parseInt(data.datatable.limit);
						self.buttonloading = false;
						self.loading = false;
						
					}
				});
			}
		},getInfoRow(id){
			if(this.permissionList.includes(this.permission.info)){
				this.buttonloading = true;
				this.submitForm.id = id;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var self = this;
				var result = postMethod('report/emailservice/info',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.dataList = data.thisDetail;
						self.$bvModal.show('infoRow');
					}
					self.buttonloading = false;
				});
			}
		},clearDataList(){
			this.dataList.code = '';
            this.dataList.sort = 10;
            this.dataList.remark = '';
			this.dataList.status = '';
			this.dataList.name = [];
		},pagination(){
			this.searchData.pagination = this.listQuery.page;
			this.searchData.limit = this.listQuery.limit;
			this.initial();
		},expandRow(row){
            this.$refs.tableTest.toggleRowExpansion(row);
        },expandChange(row,expandedRows){
            if(expandedRows.length>1){
                this.$refs.tableTest.toggleRowExpansion(expandedRows[0]);
            }
        },saveData(){
			searchForm = this.searchData;
		}
	}, created: function(){
		var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
		this.postData.language = currentLang;
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
        this.languageList = JSON.parse(getLocalStorage('languageList'));
        this.initial();
	}
}
</script>